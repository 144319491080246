import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import sendMessage from '@api/contact'

import ToggleComponentContext from '@providers/toggleComponentProvider'

import Button from '@objects/button'
import FriendlyCaptcha from '@objects/formFields/friendlyCaptcha'
import TextField from '@objects/formFields/textField'
import CheckboxField from '@objects/formFields/checkboxField'
import RichTextEditor from '@objects/formFields/richtext'

import FocusFieldError from '@components/forms/focusFieldError'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
  },
  recaptcha: {
    marginTop: '22px',
  },
}))

function ContactForm() {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: 'contact.name.error.required' })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'contact.email.error' }))
      .required(intl.formatMessage({ id: 'contact.email.error.required' })),
    text: Yup.string().required(
      intl.formatMessage({ id: 'contact.text.error.required' })
    ),
    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    friendlyCaptchaCode: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'contact.error.required' })),
  })

  const initialValues = {
    name: '',
    email: '',
    text: '',
    termsOfPrivacy: false,
    friendlyCaptchaCode: '',
  }

  function _handleSubmit(values, { resetForm, setSubmitting }) {
    sendMessage(values)
      .then((response) => {
        if (response.status === 200) {
          resetForm()
          response.data.holidayModusEnabled === false
            ? toggleOverlay(true, 'contactSuccess', 'default', {
                trackingID: 'contactSuccess',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
            : toggleOverlay(true, 'holidayMode', 'default', {
                trackingID: 'holidayMode',
                width: 'sm',
                onAcceptance: () => toggleOverlay(false),
              })
        } else {
          toggleOverlay(true, 'contactError', 'default', {
            trackingID: 'contactError',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch((err, test) => {
        toggleOverlay(true, 'contactError', 'default', {
          trackingID: 'contactError',
          width: 'sm',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form id="contact">
          <FocusFieldError />
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} md={6}>
              <TextField
                id="name-input"
                name="name"
                fullWidth
                variant="outlined"
                label={intl.formatMessage({
                  id: 'contact.name.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'contact.name.placeholder',
                })}
                autoComplete="name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                data-testid="mail-input"
                id="email-input"
                type="email"
                name="email"
                fullWidth
                variant="outlined"
                label={intl.formatMessage({
                  id: 'contact.email.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'contact.email.placeholder',
                })}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                id="text-input"
                name="text"
                label={intl.formatMessage({
                  id: `contact.text.label`,
                })}
                showInfoText
              />
            </Grid>
            <Grid item>
              <CheckboxField
                id="policy-checkbox"
                name="termsOfPrivacy"
                labelId="contact.privacy.label"
                labelLink="dataprivacy.path"
              />
              <FriendlyCaptcha
                name="friendlyCaptchaCode"
                className={classes.recaptcha}
                background="white"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                disabled={isSubmitting}
                formSubmit
                icon="TextArrow"
              >
                {intl.formatMessage({ id: 'contact.button' })}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

ContactForm.propTypes = {}

export default ContactForm
